import gql from "graphql-tag";

export default gql`
  mutation UpdateLastLogin($userId: ID!) {
    updateLastLogin(userId: $userId) {
      id
      lastLogin
    }
  }
`;
