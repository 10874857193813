import axios from "axios";

const url = process.env.REACT_APP_PRISMA_URL;

export async function getIndustries() {
  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    method: "POST",
    data: {
      query: `
      query Industries {
        industries {
            id
            name
        }
      }
      `,
    },
  });
  return result.data.data.industries;
}
