import axios from "axios";

const url = process.env.REACT_APP_PRISMA_URL;

export async function getUser() {
  const organizationId = localStorage.getItem("organizationId");
  const email = localStorage.getItem("email");

  if (!email || !organizationId) {
    return null;
  }

  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    method: "POST",
    data: {
      query: `
        query getOrganizationUsers($id: ID!, $email: String!) {
          organization(id: $id) {
            users(where: { email: $email }) {
              fname
            }
          }
        }
      `,
      variables: {
        id: organizationId,
        email: email,
      },
    },
  });

  return result.data.data.organization.users[0];
}
