import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { compose, graphql } from 'react-apollo';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
import navigationSA from '../../_navSA';
import navigationCVM from '../../_navCVM';
import navigationA from '../../_navA';
// routes config
import routes from '../../routes';

import NumberInCircle from '../../components/UnreadsNumber/CircleNumber';
import DefaultAside from './DefaultAside';
import DefaultHeader from './DefaultHeader';
import me from '../../queries/me';
import InboxUnreads from '../../queries/InboxUnreads';
import { getOrganizationsByUsers } from '../../pagination/fetchOrganizations';
import deletePushSubscription from '../../mutations/deletePushSubscription';
import { getUserPushSubsctiptions } from '../../pagination/fetchPushSubscriptions';
import { askPermission, subscribeUserToPush } from '../../pushServiceWorkerFunctions';
import AnnouncementBanner from '../../components/AnnouncementBanner/AnnouncementBanner';
import receivedMessage from '../../assets/audio/burn_complete.mp3';
import deleteSubscriptionConnections from '../../mutations/deleteSubscriptionConnections';
import './DefaultLayout.css'

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      emitUser: true
    };
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];
    this.logout = this.logout.bind(this);
    this.verifyPushSubscriptions = this.verifyPushSubscriptions.bind(this);
  }

  componentDidMount() {
    const socket = this.props.webSocket;
    if (!socket) return;

    socket.on('connect_error', (err) => {
      console.log('Socket connect error due to ', err.message);
    });
    
    socket.on('update-total-unreads', (data) => { 
      this.props.unreads.refetch(); 
      const notificationSound = () => {
        const audio = new Audio(receivedMessage);
        audio.play();
      }
      if (data && data.triggerSound) notificationSound();
    });

    const handleVisibilityChange = () => {
      if (!this.props.data.me) return;
      if (document.hidden) {
        socket.emit('rmv-active-usr', this.props.data.me.id);
        return;
      }
      socket.emit('add-active-usr', this.props.data.me.id);
    }

    const addPushUsr = () => {
      if (!this.props.data.me) return;
      socket.emit('add-active-usr', this.props.data.me.id);
    }

    const rmvPushUsr = () => {
      if (!this.props.data.me) return;
      socket.emit('rmv-active-usr', this.props.data.me.id);
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", rmvPushUsr);
    window.addEventListener("focus", addPushUsr);

    return () => {
      socket.off('update-total-unreads');
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", addPushUsr);
      window.removeEventListener("blur", rmvPushUsr);
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.props.history.push(`/login`)
  }

  async verifyPushSubscriptions() {
    if (!this.props.data.me) return;
    
    if (this.props.data.me.pushNotifications && !sessionStorage.getItem('askedPushPermission') && Notification.permission !== 'granted') {
      this.setState({
        snackbar: true
      });
      sessionStorage.setItem('askedPushPermission', true);
      return;
    }

    if (this.props.data.me.pushNotifications && !sessionStorage.getItem('subscribedPush') && Notification.permission === 'granted') {
      subscribeUserToPush(this.props.data.me.id);
      sessionStorage.setItem('subscribedPush', true);
      return;
    }

    if (!this.props.data.me.pushNotifications && !sessionStorage.getItem('verifiedPushSubscription') && Notification.permission === 'granted') {
      const subscriptions = await getUserPushSubsctiptions(this.props.data.me.id);

      // No subscriptions found
      if (!subscriptions.length) return;

      for (let subscription of subscriptions) {
        if (subscription.users.length > 1) {
          // check the organizations of the other users in that endpoint
          let noOtherUserInOrg = true;
          try {
            let organizations = await getOrganizationsByUsers(this.props.data.me.id, subscription.users);
            if (!organizations) throw new Error('Failed to fetch organizations from push.');
            if (organizations.findIndex(org => org.id === localStorage.getItem("organizationId")) !== -1){
              noOtherUserInOrg = false;
            }
          } catch (e) {
            console.log(e);
            sessionStorage.setItem('verifiedPushSubscription', true);
            return;
          }

          let variables = {
            id: subscription.id,
            userId: this.props.data.me.id
          };
          if (noOtherUserInOrg) { // if no other user shares the organization, delete that connection
            variables.organizationId = localStorage.getItem("organizationId");
          }

          const { deleteSubscriptionConnections } = this.props;

          try {
            let mutationResponse = await deleteSubscriptionConnections({variables})
            if (!mutationResponse.data) throw new Error('Failed to delete push subscription');
          } catch (e) {
            console.log(e);
          }
        }
        else {
          //delete the push subscription
          let variables = {
            id: subscription.id,
          }

          const { deletePushSubscription } = this.props;
          
          try {
            let mutationResponse = await deletePushSubscription({variables});
            if (!mutationResponse.data) throw new Error('Failed to delete push subscription');
          } catch (e) {
            console.log(e.message);
          }
        }
      }
      sessionStorage.setItem('verifiedPushSubscription', true)
    }
  }

  render() {
    const { history, data, unreads } = this.props;

    if (!localStorage.getItem('token')) {
      localStorage.setItem('redirect', window.location.pathname) 
      history.push(`/login`)
    }
    if (data.loading || unreads.loading) {
      return <div />;
    }

    /* This line of code prevents crashes when accessing  Super Admin tab */
    const { inboxEnabled } = data.me ? data.me.organization : { inboxEnabled: undefined };
    const socket = this.props.webSocket;
    if (this.state.emitUser && socket && data.me) {
      this.setState({emitUser: false});
      socket.emit('add-active-usr', this.props.data.me.id);
    }

    this.verifyPushSubscriptions();

    let navProp = navigation;
    if (localStorage.getItem('role') === "SUPERADMIN") {
      navProp = navigationSA;
    } else if (localStorage.getItem('role') === "ADMIN") {
      navProp = navigationA;
    } else if (localStorage.getItem('role') === "COUPONS") {
      navProp = navigationCVM;
    }

    const calculateLeftPadding = (number) => {
      if (number < 10) return '7px';
      if (number < 100) return '4px';
      if (number < 1000) return '1px'
      return '2px';
    }
    let inbox = {
      name: 
        <span>Inbox 
          <NumberInCircle
            number={unreads.organizationInboxUnreadsCount.sum} 
            display={unreads.organizationInboxUnreadsCount.sum ? 'inline-block' : 'none'} 
            paddingTop={'0px'}
            marginLeft={'10px'}
            paddingLeft={calculateLeftPadding(unreads.organizationInboxUnreadsCount.sum)}
            navPropItem={true}
          />
        </span>,
      url: '/inbox',
      icon: 'icon-speech'
    };

    // Add or Remove inbox tab depending on inboxEnabled
    let inboxIndex = navProp.items.findIndex(item => item.url === '/inbox');
    if ( (localStorage.getItem('role') === "SUPERADMIN" || localStorage.getItem('role') === "ADMIN" || localStorage.getItem('role') === "USER")
      && inboxEnabled
      && inboxIndex === -1 ) {
        let insertPos = navProp.items.findIndex(item => item.name === "Audiences");
        navProp.items.splice(insertPos+1, 0, inbox);
    }
    else if (!inboxEnabled && typeof inboxEnabled !== 'undefined' && inboxIndex !== -1) {
      navProp.items.splice(inboxIndex, inboxIndex-1);
    }
    if ( (localStorage.getItem('role') === "SUPERADMIN" || localStorage.getItem('role') === "ADMIN")
      && inboxEnabled
      && inboxIndex !== -1) {
        navProp.items[inboxIndex].name = inbox.name;
    }

    AppSidebar.prototype.onClickOut = () => {return}; //disable close on click outside sidebar behavior
    AppSidebarNav.prototype.hideMobile = () => {return}; //disable close sidebar on view change 

    const role = localStorage.getItem('role')
    const pathName = window.location.pathname

    return (
      <>
        <Snackbar 
          open={this.state.snackbar} 
          direction='down' 
          style={{marginTop: 30, zIndex: 1000}} 
          autoHideDuration={30000} 
          anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
          TransitionComponent={Slide} 
        >
          <Alert severity="info" onClose={() => this.setState({snackbar: false})} >
            <div className="push-alert">
              <p>
                You have set receive push notifications as true in your account but this browser is not registered
                to receive push notifications. Would you like to register it?
              </p>
              <p className='note'>
                To change your push notifications preferences, go to {'Settings > Profile > Notifications.'}
              </p>
              <div style={{display: 'flex'}}>
                <Button color='primary' type='button' onClick={() => {askPermission(data.me.id); this.setState({snackbar: false});}}>Yes</Button> {/** Add space between buttons  */}
                <Button color='danger' type='button' style={{marginLeft: '13px'}} onClick={() => this.setState({snackbar: false})}>No</Button>
              </div>
            </div>
          </Alert>
        </Snackbar>
        <div className="app">
          <AppHeader fixed>
            <DefaultHeader data={data} history={history} socket={this.props.webSocket} />
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed>
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={navProp} {...this.props} />
              <AppSidebarFooter />
            </AppSidebar>
            <main className="main">
              <AnnouncementBanner {...this.props}/>
              <Container fluid style={{ marginTop: '30px' }}>

              {role === "COUPONS" &&  (!pathName.match("/c") && !pathName.match("/vc/")) ?
                <Redirect to="/c" />
                :
                <Switch>
                  {routes.map((route, idx) => {
                      return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                          <route.component {...props} />
                        )} />)
                        : (null);
                    },
                  )}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              }
              </Container>
            </main>
            <AppAside fixed>
              <DefaultAside />
            </AppAside>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  graphql(me),
  graphql(deletePushSubscription, {name: 'deletePushSubscription'}),
  graphql(deleteSubscriptionConnections, {name: 'deleteSubscriptionConnections'}),
  graphql(InboxUnreads, { options: props => ({ variables: { id: localStorage.getItem('organizationId') } }), name: 'unreads' })
)(DefaultLayout);
