import gql from "graphql-tag";

export default gql`
  query me {
    me {
      id
      email
      fname
      lname
      role
      phone
      pushNotifications
      emailNotifications
      organization {
        id
        billingCycle
        name
        from
        inboxEnabled
        customDomain
        messagesSent
        messagesLimit
        optOutList
        aiUsageCount
        customFields(orderBy: priority_ASC) {
          id
          name
          priority
          type
        }
        tollFrees {
          id
          phone
        }
        campaigns {
          id
          name
          from
          active
          status
          createdAt
          sentAt
          campaignType
          mediaURL
          shortURL {
            id
            longURL
            shortURL
            clickCount
          }
          survey {
            id
            questions {
              id
              question
              answers {
                id
                answer
              }
            }
          }
          draft {
            id
            body
          }
          schedule {
            id
            when
            sent
          }
          message {
            id
            message
            messageCount
            totalMessages
            success
            failed
            done
            createdAt
            personalized
            lists {
              id
              name
            }
          }
        }
        locations(where: { active: true }) {
          id
          code
          shadowCode
          autoReply
          name
          address1
          address2
          city
          zipcode
          phone
          activeContacts
          optedOutContacts
          includesCoupon
          autoReplyCoupon {
            id
            name
            title
            active
            expiration
            redeemCount
          }
          coupons {
            id
            name
            title
            active
            expiration
            redeemCount
          }
          tollFree {
            id
            phone
          }
        }
      }
      subAccount {
        id
        name
      }
    }
  }
`;
