// import {split} from 'apollo-link'
// import {getMainDefinition} from 'apollo-utilities'
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

// Pages
import {
  ErrorBoundary,
  Login,
  Page404,
  Page500,
  Register,
} from "./views/Pages";
import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import DataEntry from "./views/Pages/DataEntry/DataEntry"; // Import DataEntry component
import Hotjar from "./Hotjar";
import { InMemoryCache } from "apollo-cache-inmemory";
// Containers
import WebSocketSetup from "./WebSocketSetup"; // Contains DefaultLayout container
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

const httpLink = createHttpLink({
  uri:
    process.env.REACT_APP_LW_SERV_HTTP ||
    "https://leadwire-server-dev.herokuapp.com",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const clearStore = () => {
  client.clearStore();
};

const theme = createMuiTheme({
  overrides: {
    // Example global styles
    MuiCircularProgress: {
      colorPrimary: {
        color: "var(--lw-blue-color)",
      },
    },
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Router>
            <ErrorBoundary>
              <Hotjar />
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  component={Login}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  component={Register}
                />
                <Route exact path="/404" name="Page 404" component={Page404} />
                <Route exact path="/500" name="Page 500" component={Page500} />
                <Route exact path="/data-entry" component={DataEntry} />
                <Route path="/" name="Home" component={WebSocketSetup} />
              </Switch>
            </ErrorBoundary>
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    );
  }
}

export default App;
