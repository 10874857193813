import "./Login.css";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import React, { Component } from "react";
import { Snackbar, Typography } from "@material-ui/core";

import AcceptToS from "../../../mutations/AcceptToS.js";
import AddVerificationCode from "../../../mutations/addVerificationCode.js";
import { Alert } from "@material-ui/lab";
import ChangePassword from "../../../mutations/changePassword.js";
import Image from "../../../components/Image/Image.js";
import LoginMutation from "../../../mutations/Login.js";
import Modal from "react-responsive-modal";
import Slide from "@material-ui/core/Slide";
import UpdateLastLogin from "../../../mutations/UpdateLastLogin.js";
import axios from "axios";
import { graphql } from "react-apollo";
import logo from "../../../assets/img/brand/logo_tagline.png";
import { validatePassword } from "../../../common/global.js";
import { withRouter } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: "",
    alert: false,
    onAcceptToS: false,
    onForgot: false,
    onVCode: false,
    onChangePassword: false,
    needsChangePassword: false,
    toggleSnackbar: false,
    alertInfo: {
      severity: "",
      message: "",
    },
    tempLoginData: null, // Stores login response data temporarily before verification
    mfaType: null,
    vCode: null,
    newPassword: "",
    passwordError: "",
    vCodeError: "",
    passwordJustChanged: false,
  };

  componentDidMount() {
    // Clear localStorage on mount if token exists
    if (localStorage.getItem("token")) {
      const removeList = ["token", "role", "organizationId", "organization"];
      removeList.forEach((item) => localStorage.removeItem(item));
    }
  }

  _login = async (e, email = null, password = null) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { loginMutation } = this.props;

    const variables = {
      email: email || this.state.email,
      password: password || this.state.password,
    };

    try {
      let mutationResponse = await loginMutation({ variables });
      if (mutationResponse.data) {
        const userData = mutationResponse.data.login.user;
        const active = userData.active;
        const acceptedToS = userData.acceptedToS;
        const changePassword = userData.changePassword;

        if (active === true && acceptedToS === true) {
          // Store login data temporarily
          this.setState({
            tempLoginData: mutationResponse.data.login,
            needsChangePassword: changePassword,
          });

          if (this.state.passwordJustChanged) {
            // Reset the flag before proceeding with login
            this.setState({ passwordJustChanged: false }, () => {
              // Proceed with login after state has been updated
              this._proceedWithLogin();
            });
          } else if (changePassword) {
            // User needs to change password, open Change Password modal
            this.setState({ onChangePassword: true });
          } else {
            // Generate and send verification code for MFA
            const vCodeSent = await this._addVerificationCode();
            if (vCodeSent) {
              // Show verification code modal
              this.setState({ onVCode: true });
            }
          }
        } else if (acceptedToS !== true) {
          this.setState({ onAcceptToS: true, userId: userData.id });
        } else {
          this.setState({
            alertInfo: {
              severity: "warning",
              message: "It seems like this user is not active.",
            },
            toggleSnackbar: true,
          });
        }
      } else {
        this.setState({
          alertInfo: {
            severity: "error",
            message: "An internal error occurred while trying to login.",
          },
          toggleSnackbar: true,
        });
      }
    } catch (e) {
      console.error("Login error:", e);
      this.setState({
        alertInfo: {
          severity: "error",
          message: "Email or password are invalid.",
        },
        toggleSnackbar: true,
      });
    }
  };

  _sendMessage = async (data, vCode) => {
    if (!data.phone) {
      throw new Error(
        "Phone number not found. Please contact us to update your phone number."
      );
    }

    const content = `Your LW verification code is: ${vCode}.\nDon't share this code with anyone; our employees will never ask for the code.`;
    const endpoint = process.env.REACT_APP_LEADWIRE_API + "/bulkSend";
    const body = {
      from: data.organization.from,
      text: content,
      organizationId: data.organization.id,
      tns: data.phone,
      fromInbox: false,
      mediaURL: "",
      billingCycle: data.organization.billingCycle,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.post(endpoint, body, config);
    } catch (e) {
      console.error(
        "There was a problem sending verification code via text.",
        e
      );
      throw new Error("Failed to send verification code via SMS.");
    }
  };

  _addVerificationCode = async (e, isForgotPasswordFlow = false) => {
    if (e) {
      e.preventDefault();
    }
    const { addVerificationCode } = this.props;

    const vCode = Math.floor(100000 + Math.random() * 900000);
    const trueEmail = this.state.email.toLowerCase();
    const variables = {
      email: trueEmail,
      vCode,
    };

    try {
      const mutationResponse = await addVerificationCode({ variables });
      if (mutationResponse.data) {
        const addVCodeResponse = mutationResponse.data.addVerificationCode;
        const mfaType = addVCodeResponse.mfaType;
        const phone = addVCodeResponse.phone;
        this.setState({ mfaType, vCode, isForgotPasswordFlow });

        if (mfaType === "PHONE") {
          if (!phone) {
            // Phone number is missing
            this.setState({
              alertInfo: {
                severity: "error",
                message:
                  "Phone number not found. Please contact us to update your phone number.",
              },
              toggleSnackbar: true,
            });
            return false; // Indicate failure
          }
          // Send the verification code via SMS
          try {
            await this._sendMessage(addVCodeResponse, vCode);
            this.setState({
              alertInfo: {
                severity: "success",
                message: "Verification code has been sent to your phone.",
              },
              toggleSnackbar: true,
              onForgot: false,
              onVCode: true,
              vCodeError: "",
            });
            return true; // Indicate success
          } catch (error) {
            console.error("Error sending SMS:", error);
            this.setState({
              alertInfo: {
                severity: "error",
                message:
                  "An error occurred while sending the verification code via SMS.",
              },
              toggleSnackbar: true,
            });
            return false; // Indicate failure
          }
        } else {
          // Send the verification code via email
          await this._sendVerificationEmail(trueEmail, vCode);
          this.setState({
            alertInfo: {
              severity: "success",
              message: "Verification code has been sent to your email.",
            },
            toggleSnackbar: true,
            onForgot: false,
            onVCode: true,
            vCodeError: "",
          });
          return true; // Indicate success
        }
      } else {
        // Handle case where mutationResponse.data is null
        this.setState({
          alertInfo: {
            severity: "error",
            message: "An error occurred while sending the verification code.",
          },
          toggleSnackbar: true,
        });
        return false;
      }
    } catch (e) {
      console.error("Error in _addVerificationCode:", e);
      this.setState({
        alertInfo: {
          severity: "error",
          message: "An error occurred while sending the verification code.",
        },
        toggleSnackbar: true,
      });
      return false;
    }
  };

  // Helper function to send verification email
  _sendVerificationEmail = async (email, vCode) => {
    const body = JSON.stringify({
      email: email,
      vCode: vCode,
    });
    const api = process.env.REACT_APP_LEADWIRE_API + "/vCode";
    await axios.post(api, { body });
  };

  checkVCode = (e) => {
    e.preventDefault();
    const {
      inputVCode,
      vCode,
      isForgotPasswordFlow,
      needsChangePassword,
    } = this.state;

    if (inputVCode === vCode.toString()) {
      // Verification successful
      this.setState({
        onVCode: false,
        vCodeError: "",
        alertInfo: {
          severity: "success",
          message: "Verification code accepted.",
        },
        toggleSnackbar: true,
      });

      // Check if the user needs to change password (forgot password flow or first-time login)
      if (isForgotPasswordFlow || needsChangePassword) {
        // Open Change Password modal
        this.setState({ onChangePassword: true });
      } else {
        // Proceed with login
        this._proceedWithLogin();
      }
    } else {
      // Verification failed
      this.setState({
        vCodeError: "The verification code is incorrect. Please try again.",
      });
    }
  };

  _proceedWithLogin = async () => {
    const { tempLoginData } = this.state;

    if (tempLoginData) {
      const { token, user } = tempLoginData;
      const { role, organization } = user;

      try {
        // Update last login time
        await this.props.updateLastLogin({
          variables: { userId: user.id },
        });

        // Check if this is first login (lastLogin was null)
        if (!user.lastLogin) {
          // Send first login notification email with user info
          await this._sendFirstLoginEmail(user);
        }

        // Store user data in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        localStorage.setItem("organization", organization.name);
        localStorage.setItem("organizationId", organization.id);

        // Clear sensitive data from state
        this.setState({
          password: "",
          newPassword: "",
          tempLoginData: null,
        });

        // Redirect the user to the appropriate page
        if (role === "COUPONS") {
          this.props.history.replace("/c");
        } else {
          const path = localStorage.getItem("redirect");
          localStorage.removeItem("redirect");
          if (path) {
            this.props.history.replace(path);
          } else {
            this.props.history.replace("/dashboard");
          }
        }
      } catch (error) {
        this.setState({
          alertInfo: {
            severity: "error",
            message: "An error occurred during login. Please try again.",
          },
          toggleSnackbar: true,
        });
      }
    }
  };

  _sendFirstLoginEmail = async (user) => {
    try {
      const api = process.env.REACT_APP_LEADWIRE_API + "/firstLogin";
      await axios.post(api, { user });
    } catch (error) {
      console.error("Error sending first login email:", error);
    }
  };

  render() {
    return (
      <div className="app flex-row align-items-center">
        {this.state.alert}
        <Container>
          <Row className="justify-content-center">
            <Col md="12" lg="6" xl="4">
              <center>
                <Image
                  className="login-img"
                  src={logo}
                  alt="LeadWire Logo"
                  width={250}
                  height="auto"
                />
              </center>
              <CardGroup>
                <Card className="p-4 login-boxshadow">
                  <CardBody>
                    <Form onSubmit={(e) => this._login(e)}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign in to your account</p>
                      <InputGroup className="mb-3">
                        <Input
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={this.state.password}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                      </InputGroup>
                      <Row>
                        <div className="w-100 flex justify-content-center">
                          <Button
                            type="submit"
                            className="login-btn"
                            color="primary text-center"
                            onClick={(e) => this._login(e)}
                          >
                            Login
                          </Button>
                          <br />
                        </div>
                      </Row>
                      <span
                        onClick={() => this.setState({ onForgot: true })}
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password?
                      </span>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>

        {/* Accept ToS Modal */}
        <Modal
          open={this.state.onAcceptToS}
          onClose={() => this.setState({ onAcceptToS: false })}
          className="pin-modal"
        >
          <div className="p-3">
            <div>
              <Slide in={true} direction="down" mountOnEnter unmountOnExit>
                <h5 className="text-align-center">Terms of Service</h5>
              </Slide>
            </div>
            <div className="text-center">
              <Row>
                <Col lg="12">
                  <Typography className="text-muted" variant="overline">
                    In order to use Lead Wire, we need you to accept the Terms
                    of Service.
                  </Typography>
                  <p>
                    <a
                      href="https://www.leadwireapp.com/terms-and-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            <div className="flex justify-content-between">
              <Button
                color="danger"
                onClick={() => this.setState({ onAcceptToS: false })}
              >
                CANCEL
              </Button>
              <Button color="success" onClick={() => this._acceptToS()}>
                ACCEPT
              </Button>
            </div>
          </div>
        </Modal>

        {/* Forgot Password */}
        <Modal
          open={this.state.onForgot}
          onClose={() => this.setState({ onForgot: false })}
        >
          <div className="p-3">
            <div>
              <Form onSubmit={(e) => this._addVerificationCode(e, true)}>
                <h1>Forgot Password</h1>
                <p className="text-muted">
                  We will send you an email with a code to change your password.
                </p>
                <InputGroup className="mb-3">
                  <Input
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </InputGroup>
                <Row>
                  <div className="flex justify-content-end w-100">
                    <Button type="submit" color="primary text-center">
                      SEND CODE
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Modal>

        {/* Verification Code Modal */}
        <Modal
          open={this.state.onVCode}
          onClose={() => this.setState({ onVCode: false })}
        >
          <div className="p-3">
            <Form onSubmit={(e) => this.checkVCode(e)}>
              <h1>Verification Code</h1>
              <p className="text-muted">
                {this.state.mfaType === "PHONE"
                  ? "A verification code has been sent to your phone."
                  : "A verification code has been sent to your email."}
              </p>
              <InputGroup className="mb-3">
                <Input
                  type="text"
                  placeholder="Enter Verification Code"
                  onChange={(e) =>
                    this.setState({ inputVCode: e.target.value })
                  }
                />
              </InputGroup>
              {/* Display incorrect code below the verification code field */}
              {this.state.vCodeError && (
                <Alert severity="error" className="mb-3">
                  {this.state.vCodeError}
                </Alert>
              )}
              <Row>
                <div className="flex justify-content-end w-100">
                  <Button type="submit" color="primary text-center">
                    Verify
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* Change Password Modal */}
        <Modal
          open={this.state.onChangePassword}
          onClose={() => this.setState({ onChangePassword: false })}
          center
          classNames={{
            modal: "customModal",
          }}
        >
          <div className="p-3 modal-content-transition">
            <Form onSubmit={(e) => this._changePassword(e)}>
              <h1>Change Password</h1>
              <Typography variant="body2" className="text-muted">
                Please create a new password that meets the following
                requirements:
              </Typography>
              <ul className="password-requirements">
                <li>At least 12 characters in length</li>
                <li>Includes uppercase and lowercase letters</li>
                <li>Contains numbers</li>
                <li>Contains special characters</li>
              </ul>
              <InputGroup className="mb-3 mt-3">
                <Input
                  type="password"
                  placeholder="New Password"
                  value={this.state.newPassword}
                  onChange={(e) =>
                    this.setState({ newPassword: e.target.value })
                  }
                />
              </InputGroup>
              {/* Display validation error below the password field */}
              {this.state.passwordError && (
                <Alert severity="error" className="mb-3">
                  {this.state.passwordError}
                </Alert>
              )}
              <Row>
                <div className="flex justify-content-end w-100">
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </Modal>

        {/* Snackbar */}
        <Snackbar
          open={this.state.toggleSnackbar}
          direction="down"
          style={{ zIndex: 1000 }}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={Slide}
          onClose={() => this.setState({ toggleSnackbar: false })}
        >
          <Alert
            severity={this.state.alertInfo.severity}
            onClose={() => this.setState({ toggleSnackbar: false })}
          >
            {this.state.alertInfo.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  _acceptToS = async () => {
    let { acceptToS } = this.props;

    let variables = {
      userId: this.state.userId,
    };

    try {
      let mutationResponse = await acceptToS({ variables });
      if (mutationResponse.data) {
        this.setState({ onAcceptToS: false });
        this._login();
      }
    } catch (e) {
      this.setState({
        alertInfo: {
          severity: "error",
          message: "An internal error occured while accepting ToS.",
        },
        toggleSnackbar: true,
      });
    }
  };

  // Handle password input change
  handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    this.setState({ newPassword });
  };

  _changePassword = async (e) => {
    e.preventDefault();
    const { changePassword } = this.props;

    const trueEmail = this.state.email.toLowerCase();
    const newPassword = this.state.newPassword;

    // Validate the new password
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      this.setState({
        passwordError,
        alertInfo: {
          severity: "error",
          message: passwordError,
        },
        toggleSnackbar: true,
      });
      return;
    }

    const variables = {
      email: trueEmail,
      password: newPassword,
    };

    try {
      const mutationResponse = await changePassword({ variables });
      if (mutationResponse.data) {
        this.setState({
          onChangePassword: false,
          newPassword: "",
          password: newPassword,
          passwordJustChanged: true,
          alertInfo: {
            severity: "success",
            message:
              "Your password was changed successfully! You can now log in with your new password.",
          },
          toggleSnackbar: true,
        });
      }
    } catch (e) {
      console.error("Error changing password:", e);
      this.setState({
        alertInfo: {
          severity: "error",
          message: "An error occurred while changing your password.",
        },
        toggleSnackbar: true,
      });
    }
  };
}

export default graphql(LoginMutation, { name: "loginMutation" })(
  graphql(AcceptToS, { name: "acceptToS" })(
    graphql(ChangePassword, { name: "changePassword" })(
      graphql(AddVerificationCode, { name: "addVerificationCode" })(
        graphql(UpdateLastLogin, { name: "updateLastLogin" })(withRouter(Login))
      )
    )
  )
);
